<template>
    <div class="container">

        <div class="item">
            <div class="titleday d-flex justify-content-center">
                <h1>La mulți ani, <span data-title="România" class="text">România</span>!</h1>
            </div>
            <div class="content">
                <div class="albastru"></div>
                <div class="galben"></div>
                <div class="rosu"></div>
            </div>
        </div>
        <div class="maimulte">
            <p>După unirea Transilvaniei cu România, și ziua de 1 Decembrie a devenit
                <a href="#" data-bs-toggle="modal" data-bs-target="#ModalDEC">citește
                    mai mult...</a>
            </p>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="ModalDEC" tabindex="-1" aria-labelledby="ModalDEC" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <h5 class="modal-title mt-10 text-center">1 DECEMBRIE</h5>
                <div class="modal-body">
                    <span>
                        <p>După unirea Transilvaniei cu România, și ziua de 1 Decembrie a devenit o zi de sărbătoare
                            națională,
                            fiind
                            sărbătorită mai ales în Transilvania. Nefigurând însă printre sărbătorile naționale legalizate
                            oficial, ziua
                            de 1 Decembrie a căzut în uitare.</p>
                        <p>În anul 1990, după revoluția anticomunistă din anul 1989, parlamentul dominat de FSN a refuzat
                            propunerea venită
                            din partea opoziției, de a adopta ziua de 16 decembrie drept sărbătoare națională a României. Pe
                            fondul
                            confruntărilor interetnice de la Târgu Mureș din martie 1990 și a mineriadei din 13-15 iunie
                            1990,
                            Parlamentul
                            României a adoptat la 31 iulie 1990 legea nr. 10 din 1990, prin care a fost abrogată Hotărârea
                            Consiliului de
                            Miniștri nr. 903 din 18 august 1949 privind declararea zilei de 23 August ca sărbătoare
                            națională și
                            a proclamat
                            în locul ei ziua de 1 decembrie drept sărbătoare națională. Legea 10 din 1990 nu precizează
                            semnificația sau
                            motivul alegerii zilei de 1 decembrie drept zi națională a României.</p>
                        <p>Legea adoptată în 1990 de parlamentul dominat de FSN și promulgată de Ion Iliescu a avut în
                            vedere
                            pe de o parte
                            combaterea simpatiilor legate de tradiția monarhică a României, cu sărbătoarea națională
                            istorică pe
                            10 mai, dar
                            și contracararea solicitării opoziției anticomuniste, de adoptare a zilei de 16 decembrie drept
                            sărbătoare
                            națională.</p>


                        <p>Alegerea zilei de 1 Decembrie, deși neexplicit, a făcut trimitere la Unirea Transilvaniei,
                            Banatului, Crișanei
                            și Maramureșului cu România în 1918, respectiv la Proclamația de la Alba Iulia, care a avut loc
                            la 1
                            decembrie
                            1918. Alegerea acestei zile ca sărbătoare națională a României a fost văzută drept un afront
                            adus
                            minorității
                            maghiare din România, pentru care ziua de 1 decembrie a însemnat o pierdere politică.</p>

                        <p>Prima zi națională de 1 decembrie, ale cărei festivități centrale s-au desfășurat în 1990 la Alba
                            Iulia, a fost
                            marcată de polarizare politică, discursul lui Corneliu Coposu, liderul de atunci a obiectivei
                            anticomuniste,
                            fiind întrerupt în mai multe rânduri de huiduieli. Petre Roman, prim-ministrul de atunci, s-a
                            arătat
                            încântat de întreruperea repetată a discursului liderului opoziției, ceea ce l-a făcut pe
                            președintele Ion
                            Iliescu să-i dea un semn cu mâna pentru ca să înceteze, gest filmat și difuzat pe larg de mass
                            media.</p>

                        <p> Istoricul Neagu Djuvara a arătat într-un interviu acordat TVR, în anul 2011, că alegerea zilei
                            de 1
                            decembrie de
                            către regimul Iliescu a fost una conjuncturală, explicând că la 1 decembrie 1918 a avut loc doar
                            Unirea
                            Transilvaniei și a Banatului cu România, pe când celelalte provincii istorice, respectiv
                            Basarabia
                            și Bucovina,
                            au fost unite la date diferite.</p>
                        <p>Preluat: <a
                                href="https://ro.wikipedia.org/wiki/Ziua_na%C8%9Bional%C4%83_a_Rom%C3%A2niei#1_Decembrie,_Ziua_Unirii">Wikipedia</a>
                        </p>
                    </span>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-danger" data-bs-dismiss="modal">Închide</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.maimulte p {
    text-align: center;
    font-size: small;
}

.modal-footer button {
    background-color: #e62828;
    color: white;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px;
    font-weight: bold;
    box-shadow: 1px 1px 5px #222;

    &:hover {
        background-color: #e62828cb;
    }
}

span {
    margin-bottom: 10px;
}

span p {
    text-indent: 40px;
}

h5 {
    font-weight: 300;
}

.text {
    color: transparent;
    display: inline-block;
    border-radius: 3px;
    background-color: #1d3557;
    position: relative;
    -webkit-background-clip: text;
    background-clip: text;

    &:before {
        content: attr(data-title);
        background: linear-gradient(90deg, #002a83 0%, #ffdb00 51%, #e50000 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0;
        background-clip: text;
        -webkit-background-clip: text;
        transition: all 1s ease-in-out;
        animation: text-animation 3s infinite;
    }
}


@keyframes text-animation {
    0% {
        width: 0;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}

.item {
    margin-top: 10px;
    margin-bottom: 20px;
}

.titleday {
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.titleday h1 {
    font-weight: 300;
}

.titleday h1 span {
    text-transform: uppercase;
    font-weight: 100;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: other 8s;
    animation-iteration-count: infinite;
}


.albastru {
    height: 100px;
    width: 120px;
    /* background-color: #002a83; */
    background: linear-gradient(to left, #002a83, transparent);
}

@keyframes other {
    0% {
        transform: translate(1px, -3px) rotate(-1deg);
    }

    10% {
        transform: translate(-1px, 3px) rotate(-1deg);
    }

    20% {
        transform: translate(1px, 3px) rotate(0deg);
    }

    30% {
        transform: translate(4px, 3px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, -3px) rotate(-1deg);
    }

    60% {
        transform: translate(-4px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(4px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(-4px, 0px) rotate(1deg);
    }

    100% {
        transform: translate(1px, 1px) rotate(0deg);
    }
}

/* @keyframes updown {
    0% {
        top: 0px;
    }

    50% {
        top: 10px;
    }

    100% {
        top: 0px;
    }
} */

.galben {
    height: 100px;
    width: 120px;
    background-color: #ffdb00;
}

.rosu {
    height: 100px;
    width: 120px;
    background: linear-gradient(to right, #e50000, transparent);
    /* background-color: #e50000; */

}
</style>
