<template>
  <div v-if="images != undefined && images.length > 0">
    <div class="my-row">
      <BlueMapGallery :id="'blueimp-gallery-' + bid" :images="images" :index="index" @close="index = null, id = null">
      </BlueMapGallery>
      <div class="bg-image">
        <div @click="index = 0" class="image" :style="{ backgroundImage: 'url(' + images[0] + ')' }"></div>
        <div v-for="n in imagesLength" :key="n" @click="index = n" class="image" :data-more="'+ ' + (images.length - 3)"
          :style="{ backgroundImage: 'url(' + images[n] + ')' }"></div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BlueMapGallery from "./Gallery.vue"

export default {
  name: 'Gallery',
  props: {
    images: {
      type: Array,
      default() {
        return [];
      },
    },
    bid: {
      type: Number,
      default() {
        return 0;
      }
    }
  },

  data() {
    return {
      index: null
    };
  },
  components: {
    BlueMapGallery
  },
  methods: {
  },
  computed: {
    imagesLength: function () {
      if (this.images.length > 4) {
        return 4;
      }
      else {
        return this.images.length - 1;
      }
    }
  }
};
</script>
  
  
  
<style scoped lang="scss">
.my-row {
  display: flex !important;
  align-items: flex-start;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 3px;
  width: 200px;
  height: 130px;
  cursor: pointer;
  margin-bottom: 15px;
}

.image:nth-child(+n+4) {
  display: none;
}

.image:nth-child(3):after {
  content: attr(data-more);
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 250%;
  cursor: pointer;
}

.image:nth-child(3) {
  opacity: 2;
}

@media only screen and (max-width: 770px) {
  .image {
    width: 120px;
    height: 80px;
  }
}

@media only screen and (max-width: 485px) {
  .image {
    width: 90px;
    height: 60px;
  }
}
</style>
   
 