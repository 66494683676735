<template>
    <!-- top bar -->
    <div class="top-bar d-none d-md-flex">
        <div class="container py-2 text-white">
            <div class="d-flex align-items-center px-1">
                <a href="/"><img src="../assets/my-img/logo.png" alt="logo" class="logo"></a>
                <div class="top-bar-contact ms-auto d-flex align-items-center">
                    <small class="px-1"><i class="bi bi-geo-alt-fill px-1"></i>Str. Cuza Voda, Nr. 143, Sec. 4,
                        Bucuresti
                    </small>
                    <small class="px-1"><i class="bi bi-telephone-fill px-1"></i>021 320 50 92</small>
                    <small class="px-1"><i class="bi bi-envelope-fill px-1"></i>uniune@executori.ro</small>
                    <small class="px-2"></small>
                    <normal class="me-2"><a href="https://www.facebook.com/unejromania" target="_blank"><i
                                class="bi bi-facebook"></i></a></normal>
                    <normal class=""><a href="https://www.instagram.com/unej_romania" target="_blank"><i
                                class="bi bi-instagram"></i></a></normal>

                </div>
            </div>
        </div>
    </div>
    <!-- navbar -->
    <div class="navi">
        <div class="container">
            <nav class="navbar navbar-expand-lg menu">

                <a href="/"><img src="../assets/my-img/logo2.png" alt="logo" class="logo2"
                        @click="visible = !visible, isOpen = false"></a>
                <div class="btn-contact-none"><router-link to="/contact" class="btn-contact"
                        @click="visible = !visible, isOpen = false">Contact</router-link></div>

                <button class="navbar-toggler hamburger__btn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                    aria-label="Toggle navigation" @click="navOpen = !navOpen" v-bind:class="{ active: navOpen }">
                    <span class="top"></span>
                    <span class="mid"></span>
                    <span class="bottom"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarTogglerDemo02" :class="!visible ? 'collapse' : ''">
                    <div class="navbar-nav ms-auto mb-lg-0">

                        <div class="nav-item dropdown dropdown-mega"> <!-- position-static-->
                            <a class="nav-link dropdown-toggle" style="cursor: pointer;" data-bs-toggle="dropdown"
                                data-bs-auto-close="true">Informații Generale</a>
                            <div class="dropdown-menu slideIn">
                                <div class="mega-content">
                                    <div class="container-fluid px-4">
                                        <div class="row">
                                            <div class="col-12 col-sm-12 col-md-4">
                                                <h6 class="mega-menu-title">Prezentare</h6>
                                                <div class="list-group"
                                                    @click="visible = !visible, isOpen = false">
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/despre">Despre</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/atributii-executor">Atribuții
                                                        executor</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/despre-executarea-silita">Despre
                                                        executarea
                                                        silită</router-link>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-4">
                                                <h6 class="mega-menu-title">Organizare</h6>
                                                <div class="list-group"
                                                    @click="visible = !visible, isOpen = false">
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/conducere">Președinte
                                                        /
                                                        Vicepreședinte</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/consiliu">Consiliu</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/congres">Congres</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/CNPPEJ">CNPPEJ</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/CSD">CSD</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/CAEJ">CAEJ</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/CEJ">CEJ</router-link>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-4">
                                                <h6 class="mega-menu-title">Admitere in profesie</h6>
                                                <div class="list-group"
                                                    @click="visible = !visible, isOpen = false">
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/admitere">Cum să
                                                        devin
                                                        executor</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/examene">Examene și
                                                        concursuri</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/arhiva-examene">Arhivă
                                                        examene și
                                                        concursuri</router-link>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-4">
                                                <h6 class="mega-menu-title">Proiecte</h6>
                                                <div class="list-group"
                                                    @click="visible = !visible, isOpen = false">
                                                    <router-link class="list-group-item py-1" to="/poca2022-2023">POCA
                                                        2022-2023</router-link>
                                                    <router-link class="list-group-item py-1" to="/poca2018-2021">POCA
                                                        2018-2021</router-link>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-4">
                                                <h6 class="mega-menu-title">Biroul de presă</h6>
                                                <div class="list-group"
                                                    @click="visible = !visible, isOpen = false">
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/biroul-de-presa">Biroul de
                                                        presă</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/buletinul-informativ">Buletinul
                                                        informativ</router-link>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-4">
                                                <h6 class="mega-menu-title">Diverse</h6>
                                                <div class="list-group"
                                                    @click="visible = !visible, isOpen = false">
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/protocoale">Protocoale</router-link>
                                                    <router-link class="list-group-item py-1"
                                                        to="/informatii-generale/cariera">Carieră
                                                        (Anunțuri
                                                        angajare)</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" style="cursor: pointer;" data-bs-toggle="dropdown"
                                data-bs-auto-close="true">Legislație</a>
                            <div class="dropdown-menu slideIn"
                                @click="visible = !visible, isOpen = false">
                                <router-link class="dropdown-item" to="/legislatie/legea188-2000">L.
                                    188/2000</router-link>
                                <router-link class="dropdown-item" to="/legislatie/legea17-2017">L.
                                    17/2017</router-link>
                                <router-link class="dropdown-item" to="/legislatie/statut">Statut</router-link>
                                <router-link class="dropdown-item" to="/legislatie/regulament">Regulament</router-link>
                                <router-link class="dropdown-item" to="/legislatie/OMJ">OMJ 2550/2006</router-link>
                                <router-link class="dropdown-item" to="/legislatie/CPCIV">CPCIV</router-link>
                                <router-link class="dropdown-item" to="/legislatie/PCSB">Prevenirea și combaterea spălării banilor</router-link>
                                <router-link class="dropdown-item" to="/legislatie/CAJE">Comunicarea actelor judiciare și extrajudiciare</router-link>
                            </div>
                        </div>

                        <div class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" style="cursor: pointer;" data-bs-toggle="dropdown"
                                data-bs-auto-close="true">Internațional</a>

                            <div class="dropdown-menu slideIn"
                                @click="visible = !visible, isOpen = false">
                                <a href="https://uihj.com" class="dropdown-item">UIHJ.com<img class="logo-international"
                                        src="../assets/my-img/uihj-logo.png" alt="uihj"></a>
                                <router-link class="dropdown-item" to="/newsletter-uihj">Newsletter
                                    UIHJ</router-link>
                                <a href="https://uehj.eu" class="dropdown-item">UEHJ.eu <img class="logo-international"
                                        src="../assets/my-img/uehj-logo.png" alt="uehj"></a>
                                <router-link class="dropdown-item" to="/international">Despre</router-link>
                            </div>
                        </div>

                        <div class="nav-item">
                            <router-link class="nav-link" to="/tabloul-executorilor"
                                @click="visible = !visible, navOpen = !navOpen">Tabloul
                                Executorilor</router-link>
                        </div>

                        <div class="nav-item new">
                            <!-- <span class="status">Nou!</span> new -->
                            <router-link class="nav-link" to="/harta-executorilor"
                                @click="visible = !visible, navOpen = !navOpen">Harta
                                Executorilor</router-link>
                        </div>

                        <div class="nav-item">
                            <a class="nav-link" href="https://unejr.ro" target="_blank"
                                @click="visible = !visible, navOpen = !navOpen">REPES</a>
                        </div>

                        <div class="nav-item new">
                            <!-- <span class="status">Nou!</span> new -->
                            <a class="nav-link" href="https://silex.executori.ro" target="_blank"
                                @click="visible = !visible, navOpen = !navOpen">SiLex</a>
                        </div>

                        <div class="mobile">
                            <a class="col btn-logare" href="https://www.facebook.com/profile.php?id=61560413607750"
                                target="_blank"><i class="bi bi-facebook px-2"></i></a>
                            <a class="col btn-logare" href="https://www.instagram.com/unej_romania" target="_blank"><i
                                    class="bi bi-instagram px-2"></i></a>
                            <router-link class="btn-logare" to="/contact"
                                @click="visible = !visible, navOpen = !navOpen">Contact</router-link>
                            <a class="btn-logare" href="https://executori.ro/membri/" target="_blank"><i
                                    class="bi bi-box-arrow-in-right px-1"></i> Logare Membri</a>
                            <div class="mt-4"><i class="bi bi-geo-alt-fill px-1"></i>Str. Cuza Voda, Nr. 143, Sec.
                                4, Bucuresti
                            </div>
                            <div><i class="bi bi-telephone-fill px-1"></i>021 320 50 92</div>
                            <div><i class="bi bi-envelope-fill px-1"></i>uniune@executori.ro</div>


                        </div>

                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<style scoped lang="scss">
// border dinamic
.nav-item {
    display: inline-block;
    // color: var(--primaryColor);
    text-decoration: none;
}

.nav-item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--primaryColor);
    transition: width .3s;
}

.nav-item:hover::after {
    width: 100%;
}

// new
.new {
    position: relative;
}

.status {
    padding-left: 2px;
    padding-right: 2px;
    height: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: rgb(228, 7, 7);
    position: absolute;
    color: white;
    font-size: 9px;
    right: -2px;
}

.navbar a.router-link-exact-active {
    color: var(--primaryColor);
}

// logo international
.logo-international {
    height: 15px;
    width: 25px;
}

// logo
.logo {
    max-width: 120px;
}

// logo2
.logo2 {
    max-width: 100px;
}

.logo-mobile {
    width: 100%;
    background-color: white;
}

@media only screen and (min-width : 769px) {
    .logo2 {
        display: none;
    }
}

@media only screen and (max-width : 992px) {
    .btn-contact-none {
        display: none;
    }
}

@media only screen and (max-width : 769px) {
    .container {
        width: 100%;
    }
}

// mega menu
.dropdown-mega {
    position: static;
}

.mega-menu-title {
    color: var(--primaryColor)
}

.list-group-item {
    border-color: white !important;
    font-size: .9rem;

    &:hover {
        background-color: var(--primaryColor);
        color: white;
        border-radius: 20px;
    }
}

.btn-logare {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    text-decoration: none;
    height: 30px;
    font-size: 16px;
    padding: 10px;
    font-weight: 400;
    color: white !important;
    background-color: #296CA6;
    border: solid 1px rgb(228, 228, 228);
    border-radius: 45px;

    // box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    &:hover {
        background-color: #296ca6cb;
        transition: .2s;
    }
}

// button contact
.btn-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 125px;
    text-decoration: none;
    height: 30px;
    font-weight: 400;
    color: white !important;
    background-color: #296CA6;
    border: solid 1px rgb(228, 228, 228);
    border-radius: 45px;

    // box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    &:hover {
        background-color: #296ca6cb;
        transition: .2s;
    }
}

// hamburger menu
.hamburger {
    &__btn {
        display: block;
        width: 50px;
        height: 30px;
        border: none;
        border-radius: 250px;
        position: relative;
        z-index: 100;
        appearance: none;
        cursor: pointer;
        outline: none;

        span {
            display: block;
            width: 20px;
            height: 2px;
            margin: auto;
            background: #333333cb;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // margin-bottom: -5px;
            transition: all .5s ease;

            &.top {
                transform: translateY(-5px);
            }

            &.bottom {
                transform: translateY(5px);
            }
        }

        &.active {
            .top {
                transform: rotate(-45deg);
            }

            .mid {
                transform: translateX(0px);
                opacity: 0;
            }

            .bottom {
                transform: rotate(45deg);
            }
        }

    }
}

/* navbar */
.navbar {
    background-color: white;
    height: 60px;
    margin-top: -30px;
    border-radius: 10px;
    font-weight: 600;
    z-index: 999;

    padding-left: 5px;
    padding-right: 5px;
}

.z-index {
    z-index: 9999;
}

@media only screen and (max-width : 991px) {
    .navbar-collapse {
        background: white;
        border-radius: 10px;
        border-bottom: 5px solid var(--primaryColor);
        z-index: 999;
    }

    .navbar-nav {
        padding: 20px;
    }
}

@media only screen and (min-width : 992px) {
    .hamburger__btn {
        display: none;
    }

    .mobile {
        display: none;
    }
}

@media only screen and (max-width : 768px) {
    .navbar {
        margin-top: 0px;
        border-radius: 5px;
    }
}

.mobile {
    color: #333;
    font-size: .8rem;
}

.mobile a {
    color: white;
    font-size: .8rem;
}

.dropdown-menu {
    border: 0;
    background: white;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    border-bottom: 5px solid var(--primaryColor);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}


.dropdown-item:hover {
    background-color: var(--primaryColor);
    color: white;
    border-radius: 20px;
}

.nav-link,
.dropdown-item {
    font-size: .9rem;
}

.top-bar {
    background-color: var(--primaryColor);
    height: 100px;
    font-size: .9rem;
}

.top-bar i {
    color: white;
}

.navbar-toggler {
    border: none;
    padding: 0;
    outline: none;

    &:focus {
        box-shadow: none;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

@keyframes slideIn {
    0% {
        transform: translateY(1.5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(-.1rem);
        opacity: 1;
    }

    0% {
        transform: translateY(1.5rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }

    0% {
        -webkit-transform: translateY(1.5rem);
        -webkit-opacity: 0;
    }
}

.stickey {
    position: sticky;
    top: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.192);
    z-index: 999;
}

.mobile-menu {
    position: sticky;
    top: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.192);
}
</style>

<script>
import $ from 'jquery'
export default {
    name: 'navbar',
    data() {
        return {
            visible: false,
            navOpen: false,
        }
    },
    created() {
        $(document).ready(function () {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll > 1) {
                    $(".navi").addClass("stickey");
                    $(".navi").addClass("slideIn");


                }
                else {
                    $(".navi").removeClass("stickey");
                    $(".navi").removeClass("slideIn");
                    $(".navi").addClass("z-index");

                }
            })
        })
        $(document).ready(function () {
            if ($(window).width() < 769) {
                $(".navi").addClass("stickey");
                $(".navi").addClass("mobile-menu");
                $(".navi").addClass("logare-membri");

            }
        })
    }
}
</script>