<template>
    <div class="cards">
        <div class="row mt-3">
            <div class="card">

                <!-- <a href="#ZIUAEXECUTORULUIJUDECATORESC"><img style="width: 100%; height: auto;"
                        src="../assets/my-img/universul-juridic.jpg"></a> -->

                <!-- <div id="ZIUAEXECUTORULUIJUDECATORESC" class="overlay">
                    <div class="popup">
                        <a class="close" href="#">&times;</a>
                        <img
                            src="https://www.executori.ro/api/public/static/img/news/2024/10mai.jpg"
                            alt="ZIUAEXECUTORULUIJUDECATORESC">
                    </div>
                </div>
                <div class="my-hr">
                    <div class="line"></div>
                    <img src="../assets/my-img/hr.png" style="max-height: 50px;" alt="">
                    <div class="line"></div>
                </div> -->

                <div class="col">
                    <div class="fb-page facebook" data-href="https://www.facebook.com/profile.php?id=61560413607750"
                        data-width="" data-hide-cover="false" data-show-facepile="false"></div>
                </div>

                <div class="col">
                    <h5 class="sidebar-title text-center">UniversulJuridic.ro</h5>
                    <a href="https://www.universuljuridic.ro/" class="mt-3"><img
                            style="max-width: 100%; height: auto; display: block; margin: auto;"
                            src="../assets/my-img/uj.png"></a>
                </div>

                <div class="col">
                    <h5 class="sidebar-title text-center">Despre executarea silită</h5>
                    <div class="my-text py-2 text-center">
                        <span><small class="text-muted">Executorul judecatoresc este un organ de
                                executare
                                numit
                                in
                                functie de Ministrul Justitiei care..</small></span>
                    </div>
                    <iframe style="max-height: 130px;" src="https://www.youtube.com/embed/nf9F4Ob3las"
                        title="executare">
                    </iframe>
                </div>


                <div class="col">
                    <h5 class="sidebar-title text-center">REPES</h5>
                    <div class="my-text py-2 text-center">
                        <span><small class="text-muted">Registrul de publicitate a vânzării de bunuri
                                supuse
                                executării
                                silite</small></span>
                    </div>
                    <iframe style="max-height: 130px;" src="https://www.youtube.com/embed/ZXAJq4G4V3Y" title="repes">
                    </iframe>
                </div>


                <div class="col">
                    <h5 class="sidebar-title text-center">Cum să devin executor</h5>
                    <div class="my-text py-2 text-center">
                        <span><small class="text-muted">Executor judecătoresc poate fi persoana care
                                îndeplineşte
                                următoarele condiţii: </small></span><br />
                        <span><small class="text-muted">a. are cetăţenia română şi domiciliul în
                                România</small>
                        </span>
                        <span><small class="text-muted">..</small></span>
                    </div>

                    <img class="image-center" src="../assets/my-img/executor.png" alt="executor" />
                    <div class="d-flex justify-content-center mt-2">
                        <router-link to="/informatii-generale/admitere" class="btn-carousel">DESCHIDE</router-link>
                    </div>
                </div>


                <div class="col">

                    <div id="carouselExampleControls" class="carousel slide carousel-fade zoomIn"
                        data-bs-ride="carousel">

                        <div class="carousel-indicators carousel-indicators-numbers">

                            <!-- <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"
                            ><i class="bi bi-chevron-compact-left"></i></button> -->

                            <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0"
                                class="active" aria-current="true" aria-label="Slide 1"><span></span></button>

                            <!-- <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1"
                            aria-current="true" aria-label="Slide 2"><span>2</span></button> -->


                            <!-- <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"
                            ><i class="bi bi-chevron-compact-right"></i></button> -->

                        </div>

                        <div class="carousel-inner">

                            <div class="carousel-item active">
                                <h5 class="sidebar-title text-center">Newsletter</h5>
                                <span class="py-2 d-flex justify-content-center"><small class="text-muted">Academia de
                                        Științe Juridice din
                                        România</small></span>
                                <div class="img-margin"><img class="image-center" src="../assets/my-img/asjr.png"
                                        alt="executor" /></div>
                                <router-link class="btn-carousel mb-3" to="/newsletter">DESCHIDE</router-link>
                            </div>

                            <!-- <div class="carousel-item">
                            <div class="card-body">
                                <h5 class="card-title">Lorem Ipsum</h5>
                                <p class="card-text"><small class="text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small></p>
                                <button class="btnn-carousel" data-bs-toggle="modal"
                                    data-bs-target="#ModalASJR">DESCHIDE</button>
                            </div>
                        </div> -->


                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<style scoped lang="scss">
iframe {
    width: 100%;
}

.col {
    margin-bottom: 50px;
}

.cards {
    position: sticky;
    top: -700px;
    margin-left: 10px;
    margin-right: 10px;
}

.facebook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

// .overlay {
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background: rgba(0, 0, 0, 0.7);
//     transition: opacity 500ms;
//     visibility: hidden;
//     opacity: 0;
//     z-index: 999999;
// }

// .overlay:target {
//     visibility: visible;
//     opacity: 1;
// }

// .popup {
//     padding-top: 100px;
//     margin: auto;
//     display: block;
//     width: 90%;
//     max-width: 600px;
// }

// .popup img {
//     width: 100%;
//     height: auto;
// }

// .popup .close {
//     position: absolute;
//     top: 20px;
//     right: 30px;
//     transition: all 200ms;
//     font-size: 50px;
//     font-weight: bold;
//     text-decoration: none;
//     color: white;
// }

.modal-footer button {
    background-color: #e62828;
    color: white;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px;
    font-weight: bold;
    box-shadow: 1px 1px 5px #222;

    &:hover {
        background-color: #e62828cb;
    }
}

.carousel-item {
    margin-bottom: 40px;
}

.carousel-indicators-numbers {
    button {
        font-size: 12px;
        text-indent: 0;
        margin: 0 2px;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 100%;
        line-height: 20px;
        color: #fff;
        background-color: #999;
        transition: all 0.25s ease;

        &.active,
        &:hover {
            margin: 0 2px;
            width: 20px;
            height: 20px;
            background-color: #337ab7;
        }
    }
}

.btn-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 120px;
    text-decoration: none;
    height: 30px;
    font-size: 10px;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: black;
    border: solid 1px rgb(228, 228, 228);
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-top: 15px;
    background-color: white;
}

.btn-carousel:hover {
    background-color: #296CA6;
    box-shadow: 0px 10px 30px #296CA6;
    color: #fff;
    border: transparent;
    transform: translateY(-3px);
}

.sidebar-title {
    font-size: 1.2rem;
    color: rgb(77, 77, 77);
}

.image-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    height: 60px;
}

.img-margin {
    margin: 10px;
}


.carousel {
    height: 100%;
}


.card {
    padding: 15px;
    border-radius: 15px;
    background-color: transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>

<script>
export default {
    created() {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5&appId=198998956798670";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
}
</script>
