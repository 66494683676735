import { createRouter, createWebHistory } from 'vue-router'
import Acasa from '../views/Acasa.vue'
import TabloulExecutorilor from '../views/Tabloul.vue'

const routes = [
  { path: '/', name: 'Acasa', component: Acasa },
  { path: "/tabloul-executorilor", name: "Tabloul executorilor", component: TabloulExecutorilor },
  { path: "/international", name: "Internațional", component: () => import("../views/international/Despre.vue") },
  { path: "/newsletter-uihj", name: "Newsletter UIHJ", component: () => import("../views/international/Newsletter-uihj.vue") },
  { path: "/contact", name: "Contact", component: () => import("../views/Contact.vue") },
  { path: "/arhiva", name: "Arhivă tabloul executorilor ", component: () => import("../views/ArhivaTablou.vue") },
  { path: "/legislatie/statut", name: "Statut", component: () => import("../views/legislatie/Statut.vue") },
  { path: "/legislatie/legea188-2000", name: "L. 188/2000", component: () => import("../views/legislatie/Legea188-2000.vue") },
  { path: "/legislatie/legea17-2017", name: "L. 17.2017", component: () => import("../views/legislatie/Legea17-2017.vue") },
  { path: "/legislatie/regulament", name: "Regulament", component: () => import("../views/legislatie/Regulament.vue") },
  { path: "/legislatie/OMJ", name: "OMJ 2550/2006", component: () => import("../views/legislatie/OMJ.vue") },
  { path: "/legislatie/CPCIV", name: "CPCIV", component: () => import("../views/legislatie/CPCIV.vue") },
  { path: "/informatii-generale/biroul-de-presa", name: "Biroul de presă", component: () => import("../views/generale/biroul-de-presa/BiroulPresa.vue") },
  { path: "/informatii-generale/buletinul-informativ", name: "Buletinul informativ", component: () => import("../views/generale/biroul-de-presa/Buletinul.vue") },
  { path: "/informatii-generale/despre", name: "Despre", component: () => import("../views/generale/prezentare/Despre.vue") },
  { path: "/informatii-generale/atributii-executor", name: "Atribuții executor", component: () => import("../views/generale/prezentare/AtributiiExecutor.vue") },
  { path: "/informatii-generale/despre-executarea-silita", name: "Despre executarea silită", component: () => import("../views/generale/prezentare/DespreExecutareaSilita.vue") },
  { path: "/informatii-generale/admitere", name: "Cum să devin executor", component: () => import("../views/generale/admitere-in-profesie/Admitere.vue") },
  { path: "/informatii-generale/examene", name: "Examene și concursuri", component: () => import("../views/generale/admitere-in-profesie/Examene.vue") },
  { path: "/informatii-generale/arhiva-examene", name: "Arhivă examene și concursuri", component: () => import("../views/generale/admitere-in-profesie/ArhivaExamene.vue") },
  { path: "/informatii-generale/admitere/examene/2021/sediu", name: "testtt", component: () => import("../views/generale/admitere-in-profesie/Sediu2021.vue") },

  { path: "/poca2018-2021", name: "POCA 2018-2021", component: () => import("../views/generale/proiecte/Poca2018-2021.vue") },
  { path: "/proiecte/poca/newsletter01", name: "newsletter01", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter1.vue") },
  { path: "/proiecte/poca/newsletter02", name: "newsletter02", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter2.vue") },
  { path: "/proiecte/poca/newsletter03", name: "newsletter03", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter3.vue") },
  { path: "/proiecte/poca/newsletter04", name: "newsletter04", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter4.vue") },
  { path: "/proiecte/poca/newsletter05", name: "newsletter05", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter5.vue") },
  { path: "/proiecte/poca/newsletter06", name: "newsletter06", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter6.vue") },
  { path: "/proiecte/poca/newsletter07", name: "newsletter07", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter7.vue") },
  { path: "/proiecte/poca/newsletter08", name: "newsletter08", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter8.vue") },
  { path: "/proiecte/poca/newsletter09", name: "newsletter09", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter9.vue") },
  { path: "/proiecte/poca/newsletter10", name: "newsletter10", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter10.vue") },
  { path: "/proiecte/poca/inchidere", name: "inchidere", component: () => import("../views/generale/proiecte/poca2018-2021/inchidere.vue") },
  { path: "/proiecte/poca/activitati", name: "activitati", component: () => import("../views/generale/proiecte/poca2018-2021/activitati.vue") },
  { path: "/proiecte/poca/instruire", name: "instruire", component: () => import("../views/generale/proiecte/poca2018-2021/instruire.vue") },
  { path: "/poca2022-2023", name: "POCA 2022-2023", component: () => import("../views/generale/proiecte/Poca2022-2023.vue") },
  { path: "/poca2022-2023/cursuri", name: "cursuri", component: () => import("../views/generale/proiecte/Cursuri.vue") },

  { path: "/informatii-generale/protocoale", name: "Protocoale", component: () => import("../views/generale/diverse/Protocoale.vue") },
  { path: "/informatii-generale/cariera", name: "Carieră", component: () => import("../views/generale/diverse/Cariera.vue") },
  { path: "/informatii-generale/conducere", name: "Conducere", component: () => import("../views/generale/organizare/Conducere.vue") },
  { path: "/informatii-generale/consiliu", name: "Consiliu", component: () => import("../views/generale/organizare/Consiliu.vue") },
  { path: "/informatii-generale/CEJ", name: "CEJ", component: () => import("../views/generale/organizare/CEJ.vue") },
  { path: "/informatii-generale/congres", name: "Congres", component: () => import("../views/generale/organizare/Congres.vue") },
  { path: "/informatii-generale/CSD", name: "CSD", component: () => import("../views/generale/organizare/CSD.vue") },
  { path: "/informatii-generale/CAEJ", name: "CAEJ", component: () => import("../views/generale/organizare/CAEJ.vue") },
  { path: "/informatii-generale/CNPPEJ", name: "CNPPEJ", component: () => import("../views/generale/organizare/CNPPEJ.vue") },
  { path: "/harta-executorilor", name: "Harta executorilor", component: () => import("../views/Harta.vue") },
  { path: "/newsletter", name: "Newsletter", component: () => import("../components/PageNewsletters.vue") },
  
  { path: "/legislatie/PCSB", name: "Prevenirea și combaterea spălării banilor", component: () => import("../views/legislatie/PCSB.vue") },
  { path: "/legislatie/CAJE", name: "Comunicarea actelor judiciare și extrajudiciare", component: () => import("../views/legislatie/CAJE.vue") },





// dinamic routes
  { path: "/admitere/examene/:year/:page", name: "show", component: () => import("../views/generale/admitere-in-profesie/show.vue") },
  { path: "/newsletter-uihj/:page", name: "route", component: () => import("../views/international/route.vue") },

  // { path: "/test", name: "test", component: () => import("../views/Test.vue") },

// { path: "/:pathName(.*)", name: "NotFound", component: () => import("../components/404.vue") },
  { path: "/:pathName(.*)", name: "NotFound", component: () => import("../views/Acasa.vue") },
  { path: '/.*', redirect: { name: 'Acasa' } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
    // click scroll to top
    scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router