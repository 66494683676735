<template>
  <!-- spinner -->
  <div id="spinner" class="show">
    <div class="loading"></div>
    <img src="/icon.png" alt="" style="height: 100px;">
  </div>
</template>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: #296CA6;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

#spinner {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  background-color: transparent;
  margin: 50px 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-out, visibility 0s linear 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}

#spinner.show {
  transition: opacity 1s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 2;
}
</style>

<!-- <script>
import $ from 'jquery'
export default {
  created() {
    // Spinner
    var spinner = function () {
      setTimeout(function () {
        if ($('#spinner').length > -1) {
          $('#spinner').removeClass('show');
        }
      }, -1);
    };
    spinner(-1);
  }
}
</script> -->