import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import VueCookies from 'vue-cookies';
import './assets/main.css'
import './assets/animations.css'


createApp(App)
.use(VueCookies)
.use(bootstrap)
.use(router)
.mount('#app')

