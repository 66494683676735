<template>
  <div :id="id" class="blueimp-gallery blueimp-gallery-controls" :class="{ 'blueimp-gallery-carousel': carousel }">
    <div class="slides"></div>
    <h3 class="title"></h3>
    <p class="description"></p>
    <a class="prev">
      <slot name="prev"><i class="bi bi-chevron-left"></i></slot>
    </a>
    <a style="margin-right: 30px;" class="next">
      <slot name="next"><i class="bi bi-chevron-right"></i></slot>
    </a>
    <a v-if="!carousel" class="close">
      <slot name="close"></slot>
    </a>
    <ol v-if="!carousel" class="indicator"></ol>
    <a v-if="!carousel" class="play-pause"></a>
  </div>
</template>
  
<script>
import "blueimp-gallery/css/blueimp-gallery.min.css";
import blueimp from "blueimp-gallery/js/blueimp-gallery.js";

export default {
  name: "BlueMapGallery",
  props: {
    images: {
      type: Array,
      default() {
        return [];
      }
    },

    options: {
      type: Object,
      default() {
        return {};
      }
    },

    carousel: {
      type: Boolean,
      default: false
    },

    index: {
      type: Number
    },

    id: {
      type: String,
    }
  },

  data() {
    return {
      instance: null
    };
  },

  watch: {
    index(value) {
      if (this.carousel) {
        return;
      }

      if (value !== null) {
        this.open(value);
      } else {
        if (this.instance) {
          this.instance.close();
        }

        this.$emit("close");
      }
    }
  },

  mounted() {
    if (this.carousel) {
      this.open();
    }
  },

  destroyed() {
    if (this.instance !== null) {
      this.instance.destroyEventListeners();
      this.instance.close();
      this.instance = null;
    }
  },

  methods: {
    open(index = 0) {
      const instance =
        typeof blueimp.Gallery !== "undefined" ? blueimp.Gallery : blueimp;

      const options = Object.assign(
        {
          toggleControlsOnReturn: false,
          toggleControlsOnSlideClick: false,
          closeOnSlideClick: false,
          carousel: this.carousel,
          container: `#${this.id}`,
          index,
          onopen: () => this.$emit("onopen"),
          onopened: () => this.$emit("onopened"),
          onslide: this.onSlideCustom,
          onslideend: (index, slide) =>
            this.$emit("onslideend", { index, slide }),
          onslidecomplete: (index, slide) =>
            this.$emit("onslidecomplete", { index, slide }),
          onclose: () => this.$emit("close"),
          onclosed: () => this.$emit("onclosed"),
          hidePageScrollbars: false
        },
        this.options
      );

      if (this.carousel) {
        options.container = this.$el;
      }

      this.instance = instance(this.images, options);
    },
    onSlideCustom(index, slide) {
      this.$emit("onslide", { index, slide });

      const image = this.images[index];
      if (image !== undefined) {
        const text = image.description;
        const node = this.instance.container.find(".description");
        if (text) {
          node.empty();
          node[0].appendChild(document.createTextNode(text));
        }
      }
    }
  }
};
</script>
  
<style scoped>
@media (max-width: 768px) {
  .img-left {
    height: 365px;
    max-width: 100%;
    min-width: 100%;
  }

  .img-right {
    height: 350px;
  }

  .img-right {
    display: none;
  }
}

.blueimp-gallery {
  background: rgba(0, 0, 0, 0.7);
}

.blueimp-gallery>.description {
  position: absolute;
  top: 30px;
  left: 15px;
  color: #fff;
  display: none;
}

.blueimp-gallery-controls>.description {
  display: block;
}

.blueimp-gallery>.next,

.blueimp-gallery>.prev {
  color: #fff !important;
  background: transparent;
  border: 0px;
  opacity: 0.8;
  font-size: 40px;
  font-weight: 100;
}

.blueimp-gallery>.close,
.blueimp-gallery>.title {
  fill: #fff !important;
}
</style>
  